import React, { Fragment, useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import { MenuItem } from '@mui/material';
import axios from 'axios';

import { useDispatch, useSelector } from 'react-redux';
import { selectClientData, setClientData } from '../../features/registerClients/registerClientSlice';

import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';

const today = () => {
    let date = new Date();

    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();
    let today = `${year}-${month}-${day}`;
    return today;
}

const formatDate = (obj) => {
    let date = new Date(obj);

    let day = date.getDate();
    if (day < 10) {
        day = "0" + day;
    }
    let month = date.getMonth() + 1;
    if (month < 10) {
        month = "0" + month;
    }
    let year = date.getFullYear();
    let today = `${year}-${month}-${day}`;
    return today;
}

function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
}

const BillingInfo = ({ errors }) => {

    const dispatch = useDispatch();
    const clientData = useSelector(selectClientData);

    const dispatchClientData = (e) => {
        dispatch(setClientData({ attribute: e.target.name, value: e.target.value }))
    }
    const getClientData = (attribute) => {
        return clientData[attribute] ? clientData[attribute] : ""
    }

    const initDate = () => {
        if (getClientData("date") !== "") {
            return getClientData("date");
        }
        else {
            dispatch(setClientData({ attribute: "date", value: today() }))
            return dayjs(today())
        }
    }


    const [plans, setPlans] = useState([]);
    const [selected, setSelected] = useState({});

    const [date, setDate] = useState("");
    const handleChange = (newValue) => {
        dispatch(setClientData({ attribute: "date", value: formatDate(newValue) }))
        setDate(newValue);
    };

    const handleSelection = (index) => {
        dispatch(setClientData({ attribute: "amount", value: plans[index].price }))
        setSelected(plans[index]);
    }

    useEffect(() => {

        setDate(initDate());


        axios.get(process.env.REACT_APP_API_DOMAIN + "/plans", { withCredentials: true })
            .then(result => result.data)
            .then(response => {
                setPlans(response);
                let result = response.filter(item => item.id === getClientData("plan"));
                if (result.length > 0) {
                    setSelected(result[0]);
                }
            })

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Fragment>
            <Typography variant="h6" gutterBottom>
                Datos de Facturación
            </Typography>
            <Grid container spacing={3}>

                <Grid item xs={12} sm={7}>
                    <TextField
                        error={errors.plan_id && getClientData("plan_id").length === 0 ? true : false}
                        required
                        select
                        id="plan_id"
                        name="plan_id"
                        label="Plan"
                        fullWidth
                        variant="standard"
                        value={plans.length > 0 ? getClientData("plan_id") : ""}
                        onChange={(e) => dispatchClientData(e)}
                        disabled={plans.length > 0 ? false : true}
                        helperText={errors.plan_id && getClientData("plan_id").length === 0 ? errors.plan_id : null}
                    >
                        {plans.map((option, index) =>
                            <MenuItem key={option.name + index} value={option.id} onClick={(e) => handleSelection(index)}>
                                {option.name}
                            </MenuItem>
                        )}
                    </TextField>
                </Grid>

                <Grid item xs={12} sm={5}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <MobileDatePicker
                            label="Último pago"
                            value={date}
                            onChange={handleChange}
                            renderInput={(params) =>
                                <TextField {...params}
                                    error={errors.date && getClientData("date").length === 0 ? true : false}
                                    required
                                    id="date"
                                    name="date"
                                    label="Último pago"
                                    fullWidth
                                    variant="standard"
                                    helperText={errors.date && getClientData("date").length === 0 ? errors.date : null}
                                />}
                        />
                    </LocalizationProvider>
                </Grid>
                <Grid item xs={12}>
                    <Grid container>
                        <Grid item xs={6}>
                            <Typography variant="subtitle1" sx={{ textAlign: "left" }}>
                                <strong>Valor mensual:</strong>
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant="subtitle1" sx={{ textAlign: "left" }}>
                                $ {selected.price !== undefined ? numberWithCommas(selected.price) : 0}
                            </Typography>
                        </Grid>
                    </Grid>

                </Grid>
            </Grid>
        </Fragment>
    );
}
export default BillingInfo;