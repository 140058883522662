/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Button, ButtonGroup, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Grid, IconButton, MenuItem, Slide, Switch, TextField, Typography } from "@mui/material";
import axios from "axios";
import { forwardRef, useEffect, useState } from "react";

import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import EditIcon from '@mui/icons-material/Edit';

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";

const formatDate = (obj) => {
    let date = new Date(obj);

    let day = date.getDate();
    if (day < 10) {
        day = "0" + day;
    }
    let month = date.getMonth() + 1;
    if (month < 10) {
        month = "0" + month;
    }
    let year = date.getFullYear();
    let today = `${year}-${month}-${day}`;
    return today;
}

const SlideTransition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const UserDialog = (props) => {

    const [client, setClient] = useState({})
    const [errors, setErrors] = useState({})

    const [plans, setPlans] = useState([]);
    const [selected, setSelected] = useState({});
    const [dateEdited, setDateEdited] = useState(false);

    const [date, setDate] = useState("");

    const handleEditParam = (e) => {

        let aux = { ...client };
        if (e.target.name === "status") {
            aux[e.target.name] = e.target.checked ? "Active" : "Inactive";
        }
        else {
            aux[e.target.name] = e.target.value;
        }
        aux.edited = true;
        setClient(aux);
    }

    const handleEditItem = () => {
        let aux = client;
        aux.active = "active";
        setClient({ ...aux });
    }

    const handlePutItem = () => {
        console.log(client);
        if (client.edited) {
            let aux = { ...client };
            aux.active = "loading";
            setClient({ ...aux });
            axios.put(process.env.REACT_APP_API_DOMAIN + "/users", {
                id: client.id,
                firstName: client.firstName,
                lastName: client.lastName,
                phone: client.phone,
                contact: client.contact,
                contactPhone: client.contactPhone,
                status: client.status,
                plan_id: client.plan_id,
            }, { withCredentials: true })
                .then(response => response.data)
                .then(result => {
                    console.log(result);
                    aux.active = "inactive";
                    setClient({ ...aux });
                    setErrors({})
                })
                .catch(errors => {
                    setErrors(errors.response.data);
                    console.log(errors.response.data);
                    aux.active = "active";
                    setClient({ ...aux });
                })
        }
        else if (dateEdited) {
            let aux = { ...client };
            aux.active = "loading";
            setClient({ ...aux });
            axios.get(process.env.REACT_APP_API_DOMAIN + "/payments/last/" + props.id, { withCredentials: true })
                .then(response => response.data)
                .then(result => {
                    console.log(result);
                    axios.put(process.env.REACT_APP_API_DOMAIN + "/payments", {
                        id: result.id,
                        date: formatDate(date)
                    }, { withCredentials: true })
                        .then(res => res.data)
                        .then(rst => {
                            console.log(rst);
                            aux.active = "inactive";
                            setClient({ ...aux });
                            setErrors({})
                        })
                        .catch(errors => {
                            setErrors(errors.response.data);
                            console.log(errors.response.data);
                            aux.active = "active";
                            setClient({ ...aux });
                        })
                })
                .catch(errors => {
                    setErrors(errors.response.data);
                    console.log(errors.response.data);
                    aux.active = "active";
                    setClient({ ...aux });
                })
        }
        else {
            let aux = { ...client };
            aux.active = "inactive";
            setClient({ ...aux });
        }

    }

    const handleChange = (newValue) => {
        setDateEdited(true);
        setDate(newValue);
    };


    const initDate = () => {
        console.log("LAST PAY", props.lastPay)
        let date = new Date(props.lastPay);
        let year = date.getFullYear();
        let month = date.getMonth() + 1;
        let day = date.getDate() + 1;
        let pay = `${year}-${month}-${day}`;
        return pay;
    }

    useEffect(() => {
        console.log(props);
        setDate(initDate());

        axios.get(process.env.REACT_APP_API_DOMAIN + "/users/" + props.id, { withCredentials: true })
            .then(response => response.data)
            .then(result => {
                setClient(result);
                //console.log(result);
            })
            .catch(error => {
                console.log(error);
            })
        axios.get(process.env.REACT_APP_API_DOMAIN + "/plans", { withCredentials: true })
            .then(result => result.data)
            .then(response => {
                setPlans(response);
                let result = response.filter(item => item.id === props.plan_id);
                if (result.length > 0) {
                    setSelected(result[0]);
                }
            })
    }, [])

    return (
        <Dialog
            key={props.id + "Dialog"}
            open={props.clientDialog}
            TransitionComponent={SlideTransition}
            keepMounted
            onClose={(e) => props.setClientDialog(false)}
            fullWidth={true}
            maxWidth="xs"
        >
            <DialogTitle sx={{ m: 0, p: 2 }} >
                Cliente
                <IconButton
                    aria-label="close"
                    onClick={(e) => props.setClientDialog(false)}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>

            </DialogTitle>

            <DialogContent dividers sx={{ padding: "0px 24px 16px 24px", margin: "0px 0px" }}>
                <Box>
                    <Box sx={{ position: "sticky", top: 0, zIndex: 2, paddingTop: "16px" }} style={{ backgroundColor: "white" }}>
                        <Grid container columnSpacing={3}>
                            <Grid item xs={8} sm={8}>
                                <Typography variant="h6" gutterBottom>
                                    ID: {props.id}
                                </Typography>
                            </Grid>
                            <Grid item xs={4} sm={4} align="end">
                                <ButtonGroup variant="contained" size="small" color="secondary">
                                    {client.active === "active" ?
                                        <Button variant="contained" color="secondary"
                                            onClick={(e) => handlePutItem()}>
                                            <SaveIcon />
                                        </Button>
                                        :
                                        client.active === "loading" ?
                                            <Button variant="contained" color="secondary" disabled >
                                                <SaveIcon />
                                                <CircularProgress
                                                    size={24}
                                                    sx={{
                                                        color: "secondary.dark",
                                                        position: 'absolute',
                                                        top: '50%',
                                                        left: '50%',
                                                        marginTop: '-12px',
                                                        marginLeft: '-12px',
                                                    }} />
                                            </Button>
                                            :
                                            <Button variant="contained" color="secondary"
                                                onClick={(e) => handleEditItem()} >

                                                <EditIcon />


                                            </Button>
                                    }
                                </ButtonGroup>

                            </Grid>
                        </Grid>
                    </Box>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                key={props.id + "firstName"}
                                error={errors.firstName ? true : false}
                                required
                                disabled={client.active === "active" ? false : true}
                                id="firstName"
                                name="firstName"
                                label="Nombre"
                                fullWidth
                                variant="standard"
                                value={client.firstName || ''}
                                onChange={(e) => handleEditParam(e)}
                                helperText={errors.firstName}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                key={props.id + "lastName"}
                                error={errors.lastName ? true : false}
                                required
                                disabled={client.active === "active" ? false : true}
                                id="lastName"
                                name="lastName"
                                label="Apellido"
                                fullWidth
                                variant="standard"
                                value={client.lastName || ''}
                                onChange={(e) => handleEditParam(e)}
                                helperText={errors.lastName}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                key={props.id + "phone"}
                                error={errors.phone ? true : false}
                                required
                                disabled={client.active === "active" ? false : true}
                                id="phone"
                                name="phone"
                                label="Celular"
                                fullWidth
                                variant="standard"
                                value={client.phone || ''}
                                onChange={(e) => handleEditParam(e)}
                                helperText={errors.phone}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControlLabel
                                sx={{ margin: 0 }}
                                control={
                                    <Switch
                                        id="status"
                                        name="status"
                                        checked={client.status === "Active" ? true : false}
                                        onChange={(e) => handleEditParam(e)}
                                        disabled={client.active === "active" ? false : true}
                                    />
                                } label={client.status} labelPlacement="start" />
                        </Grid>
                        <Grid item xs={12} sm={7}>
                            <TextField
                                key={props.id + "contact"}
                                error={errors.contact ? true : false}
                                required
                                disabled={client.active === "active" ? false : true}
                                id="contact"
                                name="contact"
                                label="Contacto"
                                fullWidth
                                variant="standard"
                                value={client.contact || ''}
                                onChange={(e) => handleEditParam(e)}
                                helperText={errors.contact}
                            />
                        </Grid>
                        <Grid item xs={12} sm={5}>
                            <TextField
                                key={props.id + "contactPhone"}
                                error={errors.contactPhone ? true : false}
                                required
                                disabled={client.active === "active" ? false : true}
                                id="contactPhone"
                                name="contactPhone"
                                label="Número de Contacto"
                                fullWidth
                                variant="standard"
                                value={client.contactPhone || ''}
                                onChange={(e) => handleEditParam(e)}
                                helperText={errors.contactPhone}
                            />
                        </Grid>
                        <Grid item xs={12} sm={7}>
                            <TextField
                                error={errors.plan_id && client.plan_id.length === 0 ? true : false}
                                required
                                select
                                id="plan_id"
                                name="plan_id"
                                label="Plan"
                                fullWidth
                                variant="standard"
                                value={plans.length > 0 ? client.plan_id : ""}
                                onChange={(e) => handleEditParam(e)}
                                disabled={client.active === "active" ? false : true}
                                helperText={errors.plan_id && client.plan_id.length === 0 ? errors.plan_id : null}
                            >
                                {plans.map((option, index) =>
                                    <MenuItem key={option.name + index} value={option.id}>
                                        {option.name}
                                    </MenuItem>
                                )}
                            </TextField>
                        </Grid>
                        <Grid item xs={12} sm={5}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <MobileDatePicker
                                    label="Último pago"
                                    value={date || ''}
                                    onChange={handleChange}
                                    renderInput={(params) =>
                                        <TextField {...params}
                                            key={props.id + "date"}
                                            error={errors.date && date.length === 0 ? true : false}
                                            required
                                            disabled={client.active === "active" ? false : true}
                                            id="date"
                                            name="date"
                                            label="Último pago"
                                            fullWidth
                                            variant="standard"
                                            helperText={errors.date && date.length === 0 ? errors.date : null}
                                        />
                                    }
                                />
                            </LocalizationProvider>
                        </Grid>
                    </Grid>
                </Box>

            </DialogContent>
            <DialogActions>
                <Button variant="contained" color="secondary" onClick={(e) => props.setClientDialog(false)}>Cerrar</Button>
            </DialogActions>


        </Dialog>
    )

}

export default UserDialog;