/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Button, ButtonGroup, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, IconButton, Slide, Typography } from "@mui/material";
import axios from "axios";
import { forwardRef, useEffect, useState } from "react";

import CloseIcon from '@mui/icons-material/Close';
import FingerprintIcon from '@mui/icons-material/Fingerprint';

import styles from "./ViewClients.module.css";
import useFingerReader from "../../hooks/useFingerReader";
import { useSelector } from "react-redux";
import { selectFingerprintSensorID } from "../../features/fingerprint/fingerprintSlice";


const SlideTransition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const RegisterFingerprintDialog = (props) => {

    const [finger, setFinger] = useState({});
    const [errors, setErrors] = useState({});


    const [currentFinger, setCurrentFinger] = useState(0);
    const [fingerPrints, setFingerPrints] = useState([false, false, false, false]);

    const [currentRead, startReading, stopReading,clearRead] = useFingerReader();
    const deviceId = useSelector(selectFingerprintSensorID);

    const [loading, setLoading] = useState(false);

    const getFinger = () => {
        axios.get(process.env.REACT_APP_API_DOMAIN + "/finger/" + props.id, { withCredentials: true })
            .then(response => response.data)
            .then(result => {
                console.log(result);
                setFinger(result);
            })
            .catch(error => {
                console.log(error);
                setFinger({});
            })
    }

    useEffect(() => {
        getFinger();
    }, [])

    useEffect(() => {
        if (currentRead !== "" && currentRead !== null && currentFinger > 0 && currentFinger <= 4) {
            let aux = [...fingerPrints];
            aux[currentFinger - 1] = currentRead;
            console.log(aux);
            setFingerPrints([...aux]);
            setCurrentFinger((value) => value + 1);
        }

    }, [currentRead])





    const startAdquisition = () => {
        setCurrentFinger(1);
        setFingerPrints([false, false, false, false]);
        startReading();
    }

    const handleClose = () => {
        props.setFingerprintDialog(false);
        stopReading();
        setTimeout(() => {
            setCurrentFinger(0);
            setFingerPrints([false, false, false, false]);
        }, 1000)

    }

    const clearAll = () => {
        stopReading();
        setCurrentFinger(0);
        setFingerPrints([false, false, false, false]);
    }

    const saveModel = () => {
        setLoading(true);
        let data = {
            id: props.id,
            templates: fingerPrints
        };

        if (finger.id) {
            axios.put(process.env.REACT_APP_API_DOMAIN + "/finger/enroll", data, { withCredentials: true })
                .then(response => response.data)
                .then(result => {
                    console.log(result);
                    getFinger();
                    setLoading(false);
                    clearAll();

                })
                .catch(error => {
                    console.log(error);
                    setLoading(false);
                })
        }
        else {
            axios.post(process.env.REACT_APP_API_DOMAIN + "/finger/enroll", data, { withCredentials: true })
                .then(response => response.data)
                .then(result => {
                    console.log(result);
                    getFinger();
                    setLoading(false);
                    clearAll();

                })
                .catch(error => {
                    console.log(error);
                    setLoading(false);
                })
        }

    }

    return (
        <Dialog
            key={props.id + "FgDialog"}
            open={props.fingerprintDialog}
            TransitionComponent={SlideTransition}
            keepMounted
            onClose={(e) => handleClose()}
            fullWidth={true}
            maxWidth="xs"
        >
            <DialogTitle sx={{ m: 0, p: 2 }} >
                Registro de Huella
                <IconButton
                    aria-label="close"
                    onClick={(e) => handleClose()}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>

            </DialogTitle>

            <DialogContent dividers sx={{ padding: "0px 24px 16px 24px", margin: "0px 0px" }}>
                <Box>
                    <Box sx={{ position: "sticky", top: 0, zIndex: 2, paddingTop: "16px" }} style={{ backgroundColor: "white" }}>
                        <Grid container columnSpacing={3}>
                            <Grid item xs={6} sm={6}>
                                <Typography variant="h6" gutterBottom>
                                    ID: {props.id}
                                </Typography>
                            </Grid>
                            <Grid item xs={6} sm={6} align="end">
                                <ButtonGroup variant="contained" size="small" color="secondary">
                                    {currentFinger === 0 ?
                                        <Button variant="contained" color="secondary" onClick={startAdquisition}>
                                            Comenzar
                                        </Button>
                                        :
                                        currentFinger <= 4 ?
                                            <></>
                                            :
                                            <>
                                                <Button variant="contained" color="secondary" onClick={(e) => clearAll()} disabled={loading}  >
                                                    Limpiar
                                                </Button>
                                                <Button variant="contained" color="secondary" disabled={loading} onClick={(e) => saveModel()}  >
                                                    {finger.id ? "Actualizar" : "Guardar"}
                                                    {
                                                        loading ?
                                                            <CircularProgress
                                                                size={24}
                                                                sx={{
                                                                    color: "secondary.dark",
                                                                    position: 'absolute',
                                                                    top: '50%',
                                                                    left: '50%',
                                                                    marginTop: '-12px',
                                                                    marginLeft: '-12px',
                                                                }} />
                                                            :
                                                            null
                                                    }

                                                </Button>
                                            </>

                                    }
                                </ButtonGroup>

                            </Grid>
                            {
                                finger.id ?
                                    <Grid item xs={12}>
                                        <Typography variant="p">
                                            El usuario ya tiene una huella registrada, al guardar una nueva se borrará la anterior
                                        </Typography>
                                    </Grid>
                                    :
                                    null
                            }
                        </Grid>
                    </Box>
                    <Grid container spacing={1} sx={{ mt: 1, mb: 1 }}>
                        <Grid item xs={3} align="center">
                            <FingerprintIcon sx={{ fontSize: "80px" }} className={fingerPrints[0] !== false ? styles.successIcon : currentFinger === 1 ? styles.breathingIcon : styles.neutralIcon} />
                        </Grid>
                        <Grid item xs={3} align="center">
                            <FingerprintIcon sx={{ fontSize: "80px" }} className={fingerPrints[1] !== false ? styles.successIcon : currentFinger === 2 ? styles.breathingIcon : styles.neutralIcon} />
                        </Grid>
                        <Grid item xs={3} align="center">
                            <FingerprintIcon sx={{ fontSize: "80px" }} className={fingerPrints[2] !== false ? styles.successIcon : currentFinger === 3 ? styles.breathingIcon : styles.neutralIcon} />
                        </Grid>
                        <Grid item xs={3} align="center">
                            <FingerprintIcon sx={{ fontSize: "80px" }} className={fingerPrints[3] !== false ? styles.successIcon : currentFinger === 4 ? styles.breathingIcon : styles.neutralIcon} />
                        </Grid>
                    </Grid>

                    {currentFinger === 0 ?

                        <DialogContentText>
                            El registro de huellas no está habilidato en dispositivos móviles,
                            es necesario tener conectado el lector de huellas dactilares, una vez inicie
                            el proceso las instrucciones aparecerán en éste lugar.
                        </DialogContentText>
                        :
                        currentFinger <= 4 ?
                            <>
                                <DialogContentText>
                                    Ubique el mismo dedo en el sensor hasta que las 4 huellas sean verdes.
                                </DialogContentText>
                                <DialogContentText>
                                    Conectado a : {deviceId}
                                </DialogContentText>
                            </>
                            :
                            <>
                                <DialogContentText>
                                    Huellas adquiridas correctamente, presione guardar para finalizar el proceso o
                                    limpiar para comenzar de nuevo.
                                </DialogContentText>
                                <DialogContentText>
                                    Conectado a : {deviceId}
                                </DialogContentText>
                            </>
                    }
                </Box>

            </DialogContent>
            <DialogActions>
                <Button variant="contained" color="secondary" onClick={(e) => handleClose()}>Cerrar</Button>
            </DialogActions>


        </Dialog>
    )


}

export default RegisterFingerprintDialog;