import React from "react";
import { Button, Container } from "@mui/material";
import { Typography } from "@mui/material";


import styles from "./ProductSmokingHero.module.css";


function ProductSmokingHero() {

    const message = "Hola, me encuentro interesado en los servicios de WARRIORS COMBAT y me gustaría obtener mayor información";
    const encoded = encodeURI(message);
    const phone = "573502712371";
    const whatsapp = "https://wa.me/" + phone + "?text=" + encoded;

    const chat = () => {
        window.open(whatsapp, '_blank').focus();
    }


    return (
        <Container className={styles.root} component="section">
            <hr />
            <br />
            <Button variant="outlined" onClick={chat} className={styles.button}>
                <Typography variant="h4" component="span">
                    CONTÁCTANOS
                </Typography>
            </Button>
            <br />
            <br />
        </Container>
    );
}


export default ProductSmokingHero;