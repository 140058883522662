import React, { Fragment } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import { useDispatch, useSelector } from 'react-redux';
import { selectClientData, setClientData } from '../../features/registerClients/registerClientSlice';




const ContactInfo = ({ errors, setErrors }) => {

    const dispatch = useDispatch();
    const clientData = useSelector(selectClientData);

    const dispatchClientData = (e) => {
        let aux = { ...errors };
        aux[e.target.name] = null;
        setErrors(aux);
        dispatch(setClientData({ attribute: e.target.name, value: e.target.value }))
    }
    const getClientData = (attribute) => {
        return clientData[attribute] ? clientData[attribute] : ""
    }

    return (
        <Fragment>
            <Typography variant="h6" gutterBottom>
                Datos de Contácto
            </Typography>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={7}>
                    <TextField
                        error={errors.email ? true : false}
                        required
                        id="email"
                        name="email"
                        label="E-mail"
                        type="email"
                        fullWidth
                        variant="standard"
                        value={getClientData("email")}
                        onChange={(e) => dispatchClientData(e)}
                        helperText={errors.email ? errors.email : null}
                    />
                </Grid>
                <Grid item xs={12} sm={5}>
                    <TextField
                        error={errors.phone && getClientData("phone").length === 0 ? true : false}
                        required
                        id="phone"
                        name="phone"
                        label="Télefono"
                        fullWidth
                        variant="standard"
                        value={getClientData("phone")}
                        onChange={(e) => dispatchClientData(e)}
                        helperText={errors.phone && getClientData("phone").length === 0 ? errors.phone : null}
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <TextField
                        error={errors.address && getClientData("address").length === 0 ? true : false}
                        required
                        id="address"
                        name="address"
                        label="Dirección"
                        fullWidth
                        variant="standard"
                        value={getClientData("address")}
                        onChange={(e) => dispatchClientData(e)}
                        helperText={errors.address && getClientData("address").length === 0 ? errors.address : null}
                    />
                </Grid>
            </Grid>
            <Typography variant="h6" gutterBottom sx={{ mt: "32px" }}>
                Contácto de Emergencia
            </Typography>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={7}>
                    <TextField
                        error={errors.contact && getClientData("contact").length === 0 ? true : false}
                        required
                        id="contact"
                        name="contact"
                        label="Nombres y Apellidos"
                        fullWidth
                        variant="standard"
                        value={getClientData("contact")}
                        onChange={(e) => dispatchClientData(e)}
                        helperText={errors.contact && getClientData("contact").length === 0 ? errors.contact : null}
                    />
                </Grid>
                <Grid item xs={12} sm={5}>
                    <TextField
                        error={errors.contactPhone && getClientData("contactPhone").length === 0 ? true : false}
                        required
                        id="contactPhone"
                        name="contactPhone"
                        label="Teléfono"
                        fullWidth
                        variant="standard"
                        value={getClientData("contactPhone")}
                        onChange={(e) => dispatchClientData(e)}
                        helperText={errors.contactPhone && getClientData("contactPhone").length === 0 ? errors.contactPhone : null}
                    />
                </Grid>

            </Grid>
        </Fragment>
    );
}
export default ContactInfo;