import { createTheme } from "@mui/material/styles";

const primary = {
    main: '#ff9800',
    light: 'rgb(255, 172, 51)',
}

const secondary = {
    main: '#616161',

}

const warning = {
    main: '#ffd600',
}

const error = {
    main: '#ff1744',
}

export const appTheme = createTheme({
    palette: {
        type: 'light',
        primary: primary,
        secondary: secondary,
        warning: warning,
        error: error
    },
    components: {
        MuiMobileDatePicker: {
            styleOverrides: {
                root: {
                }
            },
            defaultProps: {
                openTo: "year",
                views: ["year", "month", 'day'],
                inputFormat: "MM/DD/YYYY"
            },
        },
        MuiPickersDay: {
            styleOverrides: {
                root: {
                    "&.Mui-selected": {
                        backgroundColor: primary.main + " !important",
                    },
                    "&.Mui-selected:hover": {
                        backgroundColor: primary.main + " !important",

                    }
                },
            },
        },
        MuiTableHead: {
            styleOverrides: {
                root: {
                    backgroundColor: primary.light,
                    "& .MuiTableCell-root": {
                        fontWeight: "bold"
                    }
                }
            }
        },
        MuiPickersCalendarHeader: {
            styleOverrides: {
                root: {
                    padding: 0,
                }
            }
        }
    }


});