/* eslint-disable react-hooks/exhaustive-deps */
import React, { forwardRef, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';


import { NumericFormat } from 'react-number-format';

import axios from 'axios';
import { ButtonGroup, CircularProgress, Dialog, DialogContent, DialogTitle, Fab, IconButton, Slide, TableHead, TextField, useMediaQuery, useTheme } from '@mui/material';
import { Table, TableBody, TableCell, TableContainer, TableRow, TableFooter, TablePagination } from '@mui/material';


import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';

import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import RegisterPlan from '../RegisterPlan/RegisterPlan';

import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';



const NumberFormatCustom = forwardRef(function NumberFormatCustom(props, ref) {
    const { inputRef, onChange, ...other } = props;

    return (
        <NumericFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={values => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value
                    }
                });
            }}
            thousandSeparator
            prefix="$"
        //isNumericString
        />
    );
})

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function TablePaginationActions(props) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
        onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
        onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <Box sx={{ flexShrink: 0, ml: 2.5 }}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label="previous page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </Box>
    );
}


const PlanTable = ({ rows, setRows }) => {

    const theme = useTheme();
    const lgScreen = useMediaQuery(theme.breakpoints.down('xl'));



    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [rowsOptions, setRowsOptions] = useState([5, 10, 25, { label: 'All', value: -1 }])

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page >= 0 ? Math.max(0, ((1 + page) * rowsPerPage - rows.length) + 0) : 0;

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleEditItem = (index) => {
        let aux = [...rows]
        aux[index].active = "active";
        setRows(aux);
    }

    const handleUpdateItem = (index, value, attribute) => {
        let aux = [...rows]
        aux[index][attribute] = value;
        aux[index].changed = true;
        setRows(aux);
    }

    const getPlans = () => {
        axios.get(process.env.REACT_APP_API_DOMAIN + "/plans", { withCredentials: true })
            .then(result => result.data)
            .then(response => {
                setRows(response);
            })
    }

    const handlePutItem = (index) => {

        let aux = [...rows];
        //console.log("ROW", aux[index])
        if (aux[index].changed) {
            aux[index].active = "loading";
            setRows(aux);
            let data = { ...aux[index] };
            delete data.active;

            axios.put(process.env.REACT_APP_API_DOMAIN + "/plans", data, { withCredentials: true })
                .then(result => result.data)
                .then(response => {
                    aux[index].changed = false;
                    aux[index].active = "disabled";
                    setRows([...aux]);
                    console.log(response);
                })
                .catch(error => {
                    aux[index].active = "disabled";
                    setRows([...aux]);
                    console.log(error);
                })
        }
        else {
            aux[index].active = "disabled";
            setRows([...aux]);
        }
    }

    useEffect(() => {

        getPlans();
    }, [])

    useEffect(() => {
        if (lgScreen) {
            setRowsOptions([5]);
        }
        else {
            setRowsOptions([5, 10, 25, { label: 'All', value: -1 }]);
        }
    }, [lgScreen])

    return (
        <TableContainer component={Paper} sx={{ mt: "8px" }}>
            <Table sx={{}} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell>Plan</TableCell>
                        <TableCell>Precio</TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {(rowsPerPage > 0
                        ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        : rows
                    ).map((row, idx) => (
                        <TableRow key={row.type + idx} id={"planRow" + (idx + (rowsPerPage * page))} >
                            <TableCell component="th" scope="row" >
                                <TextField
                                    autoFocus={true}
                                    className="nameCell"
                                    variant="standard"
                                    disabled={row.active === "active" ? false : true}
                                    value={row.name}
                                    name="type"
                                    onChange={(e) => handleUpdateItem((idx + (rowsPerPage * page)), e.target.value, "name")}
                                />
                            </TableCell>
                            <TableCell >
                                <TextField
                                    autoFocus={true}
                                    className="priceCell"
                                    variant="standard"
                                    disabled={row.active === "active" ? false : true}
                                    defaultValue={row.price}
                                    InputProps={{
                                        inputComponent: NumberFormatCustom
                                    }}
                                    name="price"
                                    onChange={(e) => handleUpdateItem((idx + (rowsPerPage * page)), e.target.value, "price")}
                                />
                            </TableCell>
                            <TableCell>
                                <ButtonGroup variant="contained" size="small" color="secondary">
                                    {row.active === "active" ?


                                        <Button variant="contained" color="secondary"
                                            onClick={(e) => handlePutItem(idx + (rowsPerPage * page))}>
                                            <SaveIcon />
                                        </Button>
                                        :
                                        row.active === "loading" ?
                                            <Button variant="contained" color="secondary" disabled >
                                                <SaveIcon />
                                                <CircularProgress
                                                    size={24}
                                                    sx={{
                                                        color: "secondary.dark",
                                                        position: 'absolute',
                                                        top: '50%',
                                                        left: '50%',
                                                        marginTop: '-12px',
                                                        marginLeft: '-12px',
                                                    }} />
                                            </Button>
                                            :
                                            <Button variant="contained" color="secondary"
                                                onClick={(e) => handleEditItem(idx + (rowsPerPage * page))} >

                                                <EditIcon />


                                            </Button>

                                    }
                                </ButtonGroup>
                            </TableCell>
                        </TableRow>
                    ))}
                    {emptyRows > 0 && (
                        <TableRow style={{ height: 66 * emptyRows }}>
                            <TableCell colSpan={3} />
                        </TableRow>
                    )}
                </TableBody>
                <TableFooter>
                    <TableRow>
                        <TablePagination
                            rowsPerPageOptions={rowsOptions}
                            labelRowsPerPage="Filas"
                            colSpan={3}
                            count={rows.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            SelectProps={{
                                inputProps: {
                                    'aria-label': 'rows per page',
                                },
                                native: true,
                            }}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            ActionsComponent={TablePaginationActions}
                        />
                    </TableRow>
                </TableFooter>
            </Table>
        </TableContainer >
    );
}

const AddModal = ({ setRows }) => {
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <Fab size="small" color="secondary" aria-label="add" onClick={handleClickOpen}>
                <AddIcon color="white" />
            </Fab>
            <Dialog
                fullWidth={true}
                maxWidth={"sm"}
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle sx={{ m: 0, p: 2 }} >
                    Registro de Planes
                    <IconButton
                        aria-label="close"
                        onClick={handleClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>

                </DialogTitle>
                <DialogContent dividers>
                    <RegisterPlan setRows={setRows} />
                </DialogContent>

            </Dialog>
        </>
    )
}


const ViewPlans = () => {
    const [rows, setRows] = useState([])

    return (
        <Box component="main" maxWidth="sm">
            <Paper variant="outlined" sx={{ my: { xs: 2, md: 0 }, p: { xs: 2, md: 2 } }}>
                <Box sx={{ position: 'relative' }}>
                    <Typography component="h1" variant="h4" align="center">
                        Mis Planes
                    </Typography>
                    <Box sx={{ position: "absolute", right: "0px", top: "0px" }}>
                        <AddModal setRows={setRows} />
                    </Box>
                </Box>

                <PlanTable rows={rows} setRows={setRows} />
            </Paper>
        </Box>
    );
}
export default ViewPlans;









