
import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@mui/styles";
import { ButtonBase, Container, Typography } from "@mui/material";

const styles = theme => ({
    root: {
        marginTop: theme.spacing(8),
        marginBottom: theme.spacing(4)
    },
    images: {
        marginTop: theme.spacing(8),
        display: "flex",
        flexWrap: "wrap"
    },
    imageWrapper: {
        position: "relative",
        display: "block",
        padding: 0,
        borderRadius: 0,
        height: "40vh",
        [theme.breakpoints.down("sm")]: {
            width: "100% !important",
            height: 100
        },
        "&:hover": {
            zIndex: 1
        },
        "&:hover $imageBackdrop": {
            opacity: 0.15
        },
        "&:hover $imageMarked": {
            opacity: 0
        },
        "&:hover $imageTitle": {
            padding: "8px",
            border: "4px solid currentColor"
        }
    },
    imageButton: {
        position: "absolute",
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: theme.palette.common.white
    },
    imageSrc: {
        position: "absolute",
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        backgroundSize: "cover",
        backgroundPosition: "center 40%"
    },
    imageBackdrop: {
        position: "absolute",
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        background: theme.palette.common.black,
        opacity: 0.5,
        transition: theme.transitions.create("opacity")
    },
    imageTitle: {
        position: "relative",
        padding: `${theme.spacing(2)}px ${theme.spacing(4)}px 14px`
    },
    imageMarked: {
        height: 3,
        width: 18,
        background: theme.palette.common.white,
        position: "absolute",
        bottom: -2,
        left: "calc(50% - 9px)",
        transition: theme.transitions.create("opacity")
    }
});

const ProductCategories = (props) => {
    const { classes } = props;

    const images = [
        {
            url: "/static/servicios/taekwondo.jpg",
            title: "TAEKWONDO",
            width: "33%"
        },
        {
            url: "/static/servicios/boxing.jpg",
            title: "BOX-KICKBOXING",
            width: "34%"
        },
        {
            url: "/static/servicios/personal.jpg",
            title: "DEFENSA PERSONAL",
            width: "33%"
        },
        {
            url: "/static/servicios/artistic.jpg",
            title: "GIMNASIA ARTÍSTICA",
            width: "35%"
        },
        {
            url: "/static/servicios/cheerleading.jpg",
            title: "PORRISMO",
            width: "35%"
        },
        {
            url: "/static/servicios/dance.jpg",
            title: "DANZA",
            width: "30%"
        },
        {
            url: "/static/servicios/ballet.jpeg",
            title: "PRE BALLET",
            width: "35%"
        },
        {
            url: "/static/servicios/aerobics.jpg",
            title: "RUMBA",
            width: "30%"
        },
        {
            url: "/static/servicios/functional.jpg",
            title: "ENTRENAMIENTO FUNCIONAL",
            width: "35%"
        }
    ];

    return (
        <Container className={classes.root} component="section">
            <Typography variant="h4" marked="center" align="center" component="h2">
                Nuestros Servicios
            </Typography>
            <div className={classes.images}>
                {images.map(image => (
                    <ButtonBase
                        key={image.title}
                        className={classes.imageWrapper}
                        style={{
                            width: image.width
                        }}
                    >
                        <div
                            className={classes.imageSrc}
                            style={{
                                backgroundImage: `url(${image.url})`
                            }}
                        />
                        <div className={classes.imageBackdrop} />
                        <div className={classes.imageButton}>
                            <Typography
                                component="h3"
                                variant="h6"
                                color="inherit"
                                className={classes.imageTitle}
                            >
                                {image.title}
                                <div className={classes.imageMarked} />
                            </Typography>
                        </div>
                    </ButtonBase>
                ))}
            </div>
        </Container>
    );
}

ProductCategories.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(ProductCategories);