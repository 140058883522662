/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux";
import { selectFingerprintRead, selectFingerprintSensor, setId, setRead, setSensor } from "../features/fingerprint/fingerprintSlice";

import { FingerprintSdk } from '../fingerprint_reader/api/sdk_mod'



const useFingerReader = () => {

    const fingerRead = useSelector(selectFingerprintRead)
    const fingerprintSensor = useSelector(selectFingerprintSensor);
    const [currentRead, setCurrentRead] = useState("");

    const dispatch = useDispatch();

    useEffect(() => {
        if (fingerprintSensor === null) {
            dispatch(setSensor(new FingerprintSdk(handleCapture)))
        }
    }, [])

    useEffect(() => {
        if (fingerRead !== "") {
            setCurrentRead(fingerRead);
        }
    }, [fingerRead])

    const handleCapture = (s) => {
        console.log(s);
        let samples = JSON.parse(s.samples)[0].Data;
        samples.replace("+", "-");
        samples.replace("/", "_");
        samples.replace("=", "");
        dispatch(setRead(samples));
    }

    const startReading = () => {
        fingerprintSensor.getDeviceList()
            .then(devices => {
                dispatch(setId(devices[0]))
                console.log(devices[0]);
                fingerprintSensor.startCapture();
            })
            .catch(error => console.log(error))
    }

    const stopReading = () => {
        fingerprintSensor.getDeviceList()
            .then(devices => {
                dispatch(setId(devices[0]))
                console.log(devices[0]);
                fingerprintSensor.stopCapture();
            })
            .catch(error => console.log(error))
    }

    const clearRead = () => {
        dispatch(setRead(null));
        setCurrentRead(null);
    }

    return [currentRead, startReading, stopReading, clearRead];
}

export default useFingerReader;