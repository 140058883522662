import React, { forwardRef, Fragment, useState } from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import { NumericFormat } from 'react-number-format';

import axios from 'axios';
import { CircularProgress, Grid, TextField } from '@mui/material';


const NumberFormatCustom = forwardRef(function NumberFormatCustom(props, ref) {
    const { inputRef, onChange, ...other } = props;

    return (
        <NumericFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={values => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value
                    }
                });
            }}
            thousandSeparator
            prefix="$"
        //isNumericString
        />
    );
})




const RegisterPlan = (props) => {

    const [name, setName] = useState("")
    const [type, setType] = useState("")
    const [price, setPrice] = useState("")
    const [loading, setLoading] = useState(false);
    const [created, setCreated] = useState(false);

    const [errors, setErrors] = useState({})

    const getPlans = () => {
        axios.get(process.env.REACT_APP_API_DOMAIN + "/plans", { withCredentials: true })
            .then(result => result.data)
            .then(response => {
                props.setRows(response);
            })
    }

    const handleNewPlan = () => {
        let data = { name, type, price }
        console.log(data);
        setLoading(true);
        axios.post(process.env.REACT_APP_API_DOMAIN + "/plans", data, { withCredentials: true })
            .then(result => result.data)
            .then(response => {
                console.log(response)
                setName("");
                setType("");
                setPrice("");
                setLoading(false);
                setCreated(true);
                setErrors({});
                getPlans();
            })
            .catch(error => {
                console.log(error.response.data)
                setLoading(false);
                setErrors(error.response.data)
            })
    }


    return (
        <>
            <Box component="main" maxWidth="sm">
                {!created ?
                    <Fragment>
                        <br />
                        <Typography variant="h6" gutterBottom>
                            Datos del Plan
                        </Typography>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    error={errors.name ? true : false}
                                    required
                                    id="name"
                                    name="name"
                                    label="Nombre"
                                    fullWidth
                                    variant="standard"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                    helperText={errors.name}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    error={errors.price ? true : false}
                                    required
                                    label="Valor Mensual"
                                    value={price}
                                    onChange={(e) => setPrice(parseInt(e.target.value))}
                                    name="price"
                                    id="price"
                                    fullWidth
                                    variant="standard"
                                    InputProps={{
                                        inputComponent: NumberFormatCustom
                                    }}
                                    helperText={errors.price}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <TextField
                                    error={errors.type ? true : false}
                                    required
                                    multiline
                                    maxRows={4}
                                    id="type"
                                    name="type"
                                    label="Descripción"
                                    fullWidth
                                    variant="standard"
                                    value={type}
                                    onChange={(e) => setType(e.target.value)}
                                    helperText={errors.type}
                                />
                            </Grid>
                        </Grid>
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Box sx={{ position: "relative", mt: 3, ml: 1 }}>
                                <Button
                                    variant="contained"
                                    disabled={loading}
                                    onClick={handleNewPlan}
                                >
                                    Registrar
                                </Button>
                                {loading && (
                                    <CircularProgress
                                        size={24}
                                        sx={{
                                            color: "primary.main",
                                            position: 'absolute',
                                            top: '50%',
                                            left: '50%',
                                            marginTop: '-12px',
                                            marginLeft: '-12px',
                                        }}
                                    />
                                )}
                            </Box>
                        </Box>
                    </Fragment>
                    :
                    <Fragment>
                        <br />
                        <Typography variant="h6" gutterBottom>
                            Plan Registrado Correctamente
                        </Typography>
                        <Typography variant="subtitle1">
                            El plan {type} fue registrado exitosamente.
                            Para registrar un nuevo plan haga click en el botón "Nuevo Plan".
                        </Typography>
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Box sx={{ position: "relative", mt: 3, ml: 1 }}>
                                <Button
                                    variant="contained"
                                    disabled={loading}
                                    onClick={(e) => setCreated(false)}
                                >
                                    Nuevo Plan
                                </Button>
                            </Box>
                        </Box>
                    </Fragment>
                }
            </Box>

        </>
    );
}
export default RegisterPlan;
