import { Box, Grid, List, ListItem, Typography } from "@mui/material";
import React from "react";
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import LibraryBooksOutlinedIcon from '@mui/icons-material/LibraryBooksOutlined';
import PrivacyTipOutlinedIcon from '@mui/icons-material/PrivacyTipOutlined';

import styles from "./AppFooter.module.css";

const AppFooter = () => {
    return (

        <Box sx={{
            bgcolor: "secondary.main",
            height: "fitContent",
            width: "100%",
            paddingY: "16px",
        }}>
            <Grid container columnSpacing={12} sx={{ paddingX: "16px" }}>
                <Grid item xs={12} md={3} sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center"
                }}>
                    <img src="/media/img/logos/WTC.svg" alt="LOGO" className={styles.logo} />
                </Grid>
                <Grid item xs={12} md={3} color="white">
                    <List>
                        <ListItem>
                            <Typography variant="h5">
                                Nuestras Redes
                            </Typography>
                        </ListItem>
                        <ListItem>
                            <InstagramIcon />
                            <Typography variant="h6" sx={{ marginLeft: "4px" }}>
                                Instagram
                            </Typography>
                        </ListItem>
                        <ListItem>
                            <FacebookIcon />
                            <Typography variant="h6" sx={{ marginLeft: "4px" }}>
                                Facebook
                            </Typography>
                        </ListItem>
                    </List>
                </Grid>
                <Grid item xs={12} md={3} color="white">
                    <List>
                        <ListItem>
                            <Typography variant="h5">
                                Legal
                            </Typography>
                        </ListItem>
                        <ListItem>
                            <LibraryBooksOutlinedIcon />
                            <Typography variant="h6" sx={{ marginLeft: "4px" }}>
                                Términos y Condiciones
                            </Typography>
                        </ListItem>
                        <ListItem>
                            <PrivacyTipOutlinedIcon />
                            <Typography variant="h6" sx={{ marginLeft: "4px" }}>
                                Politicas de privacidad
                            </Typography>
                        </ListItem>
                    </List>
                </Grid>
                <Grid item xs={12} md={3} sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center"
                }}>
                    <img src="/media/img/logos/Warriors.svg" alt="LOGO" className={styles.logo} />
                </Grid>
            </Grid>
            <br></br>
            <Typography variant="p" color="white" component="a" href="https://www.rivarengineering.com">
                Aplicación Web creada por RIVAR ENGINEERING S.A.S
            </Typography>
        </Box >
    )
}
export default AppFooter;