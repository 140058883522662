import React from "react";
import { Button } from "@mui/material";
import Typography from '@mui/material/Typography';
import ProductHeroLayout from "../ProductHeroLayout/ProductHeroLayout";

import styles from "./ProductHero.module.css";

const backgroundImage = "/media/img/logos/Lion.svg";

const ProductHero = () => {

    const message = "Hola, me encuentro interesado en los servicios de WARRIORS COMBAT y me gustaría agendar una clase de prueba";
    const encoded = encodeURI(message);
    const phone = "573502712371";
    const whatsapp = "https://wa.me/" + phone + "?text=" + encoded;

    const chat = () => {
        window.open(whatsapp, '_blank').focus();
    }

    return (
        <ProductHeroLayout backgroundClassName={styles.background}>
            {/* Increase the network loading priority of the background image. */}
            <img style={{ display: "none" }} src={backgroundImage} alt="" />
            <Typography
                variant="h2"
                align="center"
                sx={{
                    fontFamily: 'monospace',
                    fontWeight: 500,
                    color: 'inherit',
                    textDecoration: 'none',
                }}
            >
                Bienvenidos a WARRIORS TRAINING COMBAT
            </Typography>
            <br />
            <Typography
                variant="h5"
                align="center"
                sx={{
                    fontFamily: 'monospace',
                    fontWeight: 300,
                    color: 'inherit',
                    textDecoration: 'none',
                }}
            >
                Asume el reto WARRIORS para entrenar tu cuerpo y fortalecer tu mente
            </Typography>
            <br />
            <br />
            <Button
                color="secondary"
                variant="contained"
                size="large"
                className={styles.button}
                onClick={chat}
            >
                Agendar
            </Button>
            <br />
            <br />
            <Typography
                variant="h5"
                align="center"
                sx={{
                    fontFamily: 'monospace',
                    fontWeight: 50,
                    color: 'inherit',
                    textDecoration: 'none',
                }}
            >
                Descubre la experiencia agendando una clase de prueba
            </Typography>
        </ProductHeroLayout>
    );
}

export default ProductHero;
