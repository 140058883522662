import React, { Fragment, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import PersonInfo from '../PersonInfo/PersonInfo';
import ContactInfo from '../ContactInfo/ContactInfo';
import BillingInfo from '../BillingInfo/BillingInfo';
import { useDispatch, useSelector } from 'react-redux';
import { clearClientData, selectClientData } from '../../features/registerClients/registerClientSlice';
import axios from 'axios';
import { CircularProgress } from '@mui/material';


const steps = ['Datos Personales', 'Datos de contacto', 'Datos de Facturación'];



const RegisterClient = (props) => {
    const [activeStep, setActiveStep] = useState(0);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({})

    const dispatch = useDispatch();
    const clientData = useSelector(selectClientData);

    const getClientData = (attribute) => {
        return clientData[attribute] ? clientData[attribute] : ""
    }

    const getClients = () => {
        axios.get(process.env.REACT_APP_API_DOMAIN + "/users", { withCredentials: true })
            .then(result => result.data)
            .then(response => {
                props.setRows(response);
            })
    }

    const getStepContent = (step) => {
        switch (step) {
            case 0:
                return <PersonInfo errors={errors} setErrors={setErrors} />;
            case 1:
                return <ContactInfo errors={errors} setErrors={setErrors} />;
            case 2:
                return <BillingInfo errors={errors} setErrors={setErrors} />;
            default:
                throw new Error('Unknown step');
        }
    }

    const handleNext = () => {
        let incre = activeStep + 1

        if (incre === steps.length) {
            setLoading(true);
            let dataCp = { ...clientData }
            dataCp.status = "Active";
            axios.post(process.env.REACT_APP_API_DOMAIN + "/register", dataCp, { withCredentials: true })
                .then(result => result.data)
                .then(response => {
                    console.log(response)
                    setLoading(false);
                    setActiveStep(incre);
                    getClients();

                })
                .catch(error => {
                    setLoading(false);
                    setErrors(error.response.data);
                    console.log(error.response.data);
                })
            return
        }
        setActiveStep(incre);
    };

    const handleBack = () => {
        setActiveStep(activeStep - 1);
    };

    const handleNewClient = () => {
        setActiveStep(0);
        dispatch(clearClientData());
    };


    return (
        <Box component="main" maxWidth="sm">
            <Stepper activeStep={activeStep} alternativeLabel sx={{ pt: 3, pb: 5 }}>
                {steps.map((label) => (
                    <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>
            <Fragment>
                {activeStep === steps.length ? (
                    <Fragment>
                        <Typography variant="h5" gutterBottom>
                            Cliente Registrado Correctamente
                        </Typography>
                        <Typography variant="subtitle1">
                            El cliente {getClientData("firstName")} {getClientData("lastName")} fue registrado
                            exitosamente. Para registrar un nuevo cliente haga click en el botón "Nuevo Cliente".
                        </Typography>
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Button
                                variant="contained"
                                onClick={handleNewClient}
                                sx={{ mt: 3, ml: 1 }}
                            >
                                Nuevo Cliente
                            </Button>
                        </Box>
                    </Fragment>
                ) : (
                    <Fragment>
                        {getStepContent(activeStep)}
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                            {activeStep !== 0 && (
                                <Button onClick={handleBack} sx={{ mt: 3, ml: 1 }}>
                                    Previo
                                </Button>
                            )}
                            {activeStep !== steps.length - 1 ?
                                <Button
                                    variant="contained"
                                    onClick={handleNext}
                                    sx={{ mt: 3, ml: 1 }}
                                >
                                    Siguiente
                                </Button>
                                :
                                <Box sx={{ position: "relative", mt: 3, ml: 1 }}>
                                    <Button
                                        variant="contained"
                                        disabled={loading}
                                        onClick={handleNext}
                                    >
                                        Registrar
                                    </Button>
                                    {loading && (
                                        <CircularProgress
                                            size={24}
                                            sx={{
                                                color: "primary.main",
                                                position: 'absolute',
                                                top: '50%',
                                                left: '50%',
                                                marginTop: '-12px',
                                                marginLeft: '-12px',
                                            }}
                                        />
                                    )}
                                </Box>}
                        </Box>
                    </Fragment>
                )}
            </Fragment>
        </Box>
    );
}
export default RegisterClient;
