import { createSlice } from '@reduxjs/toolkit'


export const fingerprintSlice = createSlice({
    name: 'fingerprint',
    initialState: {
        fingerReader: null,
        currentRead: null,
        id: null,
        state: false,
    },
    reducers: {
        setSensor: (state, action) => {
            state.fingerReader = action.payload;
        }
        ,
        setRead: (state, action) => {
            state.currentRead = action.payload;
        },
        setId: (state, action) => {
            state.id = action.payload;
        },
        setState: (state, action) => {
            state.state = action.payload;
        }
    }
})

export const { setSensor, setRead, setId, setState } = fingerprintSlice.actions


export const selectFingerprintRead = (state) => state.fingerprint.currentRead;
export const selectFingerprintSensor = (state) => state.fingerprint.fingerReader;
export const selectFingerprintSensorID = (state) => state.fingerprint.id;


export default fingerprintSlice.reducer