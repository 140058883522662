import React, { Fragment, useEffect } from "react";

import styles from './WelcomePage.module.css';

import ResponsiveAppBar from "../../Navs/ResponsiveAppBar/ResponsiveAppBar ";
import ProductHero from "../../ProductHero/ProductHero";
import ProductValues from "../../ProductValues/ProductValues";
import ProductCategories from "../../ProductCategories/ProductCategories";
import ProductSmokingHero from "../../ProductSmokingHero/ProductSmokingHero";
import AppFooter from "../../AppFooter/AppFooter";





const WelcomePage = () => {

    useEffect(() => {

    })

    return (
        <Fragment>
            <ResponsiveAppBar />
            <ProductHero />
            <ProductValues />
            <ProductCategories />
            <ProductSmokingHero />
            <AppFooter />
        </Fragment>
    );
}

export default WelcomePage;