/* eslint-disable react-hooks/exhaustive-deps */
import axios from "axios";
import { useEffect, useState } from "react"
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { clearId, clearRole, clearToken, logout } from "../features/users/userSlice";



const useToken = () => {
    const [token, setToken] = useState("waiting");
    const navigate = useNavigate();

    const dispatch = useDispatch();

    const verifyToken = () => {
        axios.get(process.env.REACT_APP_API_DOMAIN + "/token", { withCredentials: true })
            .then(response => response.data)
            .then(result => {
                setToken("valid");
            })
            .catch(error => {
                setToken("invalid");
            })
    }
    const clearSession = () => {
        axios.delete(process.env.REACT_APP_API_DOMAIN + "/session", { withCredentials: true })
            .then(response => response.data)
            .then(result => {
                console.log(result);
                dispatch(clearId());
                dispatch(clearRole());
                dispatch(clearToken());
                dispatch(logout());
                navigate("/");
            })
            .catch(error => {
                console.log(error);
            })
    }

    useEffect(() => {
        if (token === "invalid") {
            clearSession();
        }
    }, [token])

    return [token, setToken, verifyToken];
}

export default useToken;