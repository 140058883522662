/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Slide } from "@mui/material";
import axios from "axios";
import { forwardRef, useEffect, useState } from "react";

import CloseIcon from '@mui/icons-material/Close';

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { CalendarPicker } from "@mui/x-date-pickers/CalendarPicker";
import dayjs from "dayjs";

import { toPng } from 'html-to-image';

import Invoice from "./Invoice";


const formatDate = (obj) => {
    let date = new Date(obj);

    let day = date.getDate();
    if (day < 10) {
        day = "0" + day;
    }
    let month = date.getMonth() + 1;
    if (month < 10) {
        month = "0" + month;
    }
    let year = date.getFullYear();
    let today = `${year}-${month}-${day}`;
    return today;
}

const SlideTransition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function numberWithDots(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
}

const whatsapp = "https://wa.me/";

const paymentMessage = (data) => {
    let msg = `Hola ${data.firstName},
    Este es un mensaje automatizado de WARRIORS COMBAT para informarte que tu pago por $${numberWithDots(data.price)} de tu  plan "${data.plan}" se realizó satisfactoriamente`
    return `${whatsapp}57${data.phone}?text=${encodeURIComponent(msg)}`
}

const PaymentDialog = (props) => {

    const [payDay, setPayDay] = useState(dayjs(props.nextPay));

    const [payDone, setPayDone] = useState(false);

    const [exporting, setExporting] = useState(false);

    const handleRegisterPayment = () => {

        axios.get(process.env.REACT_APP_API_DOMAIN + "/plans/" + props.plan_id, { withCredentials: true })
            .then(result => result.data)
            .then(response => {
                let data = {
                    amount: response.price,
                    user_id: props.id,
                    date: formatDate(payDay)
                }
                console.log(data);
                axios.post(process.env.REACT_APP_API_DOMAIN + "/payments", data, { withCredentials: true })
                    .then(res => res.data)
                    .then(resp => {
                        console.log("PAYMENT OK", resp);
                        setPayDone(true);
                        setExporting(true);
                        props.getClients();
                        window.open(paymentMessage(props), '_blank').focus();
                    })
            })
    }

    const handlePaymentOk = () => {
        props.setPayDialog(false);
        setTimeout(() => {
            setPayDone(false);
        }, 1000);
    }

    const generateInvoice = () => {
        let factura = document.getElementById("Invoice" + props.id);
        toPng(factura, { cacheBust: true, })
            .then((dataUrl) => {
                setExporting(false);
                //dataURL is base64 Image
                const link = document.createElement('a')
                link.download = props.firstName + "_" + props.lastName + "_" + formatDate(payDay) + ".png"
                link.href = dataUrl
                link.click()
            })
            .catch((err) => {
                console.log(err)
            })
    }

    useEffect(() => {
        if (exporting) {
            generateInvoice();
        }
    }, [exporting])

    return (
        <Dialog
            open={props.payDialog}
            TransitionComponent={SlideTransition}
            keepMounted
            onClose={(e) => props.setPayDialog(false)}
            fullWidth={true}
            maxWidth="xs"
        >
            <DialogTitle sx={{ m: 0, p: 2 }} >
                Registro de Pagos
                <IconButton
                    aria-label="close"
                    onClick={(e) => props.setPayDialog(false)}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>

            </DialogTitle>
            {!payDone ?
                <>
                    <DialogContent dividers>
                        <DialogContentText>
                            Seleccione la fecha de corte del pago que se está realizando.
                        </DialogContentText>
                        <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <CalendarPicker date={payDay} onChange={(newDate) => setPayDay(newDate)} sx={{ margin: 0 }} />
                            </LocalizationProvider>
                        </Box>

                        <DialogContentText>
                            Fecha sugerida {props.nextPay}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" color="secondary" onClick={(e) => props.setPayDialog(false)}>Cancelar</Button>
                        <Button variant="contained" color="primary" onClick={(e) => handleRegisterPayment()}>Guardar</Button>
                    </DialogActions>
                </>
                :
                <>
                    <DialogContent dividers>
                        <DialogContentText>
                            El registro del pago {formatDate(payDay)} se realizó correctamente.
                        </DialogContentText>
                        <div id={"Invoice" + props.id}>
                            <Invoice {...props} registeredPay={formatDate(payDay)} />
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" color="primary" onClick={(e) => handlePaymentOk()}>Aceptar</Button>
                    </DialogActions>
                </>
            }
        </Dialog>

    )

}

export default PaymentDialog;