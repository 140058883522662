import { createSlice } from '@reduxjs/toolkit'

export const registerClientSlice = createSlice({
    name: 'registerClient',
    initialState: {
        id: null,
        firstName: null,
        lastName: null,
        rh: null,
        gender: null,
        birthday: null,
        email: null,
        phone: null,
        address: null,
        contact: null,
        contactPhone: null,
        plan_id: null,
        amount: null,
        date: null,

    },
    reducers: {
        setClientData: (state, action) => {
            state[action.payload.attribute] = action.payload.value;
        },
        clearClientData: state => {
            // eslint-disable-next-line no-unused-vars
            for (const [key, values] of Object.entries(state)) {
                state[key] = null;
            }
        }
    }
})

export const { setClientData, clearClientData } = registerClientSlice.actions

export const setIdAsync = () => (dispatch) => {
    setTimeout(() => {
        dispatch(clearClientData())
    }, 1000)
}

export const selectClientData = (state) => state.registerClient


export default registerClientSlice.reducer