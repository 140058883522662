import React, { Fragment, useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import { MenuItem } from '@mui/material';


import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { useDispatch, useSelector } from 'react-redux';
import { selectClientData, setClientData } from '../../features/registerClients/registerClientSlice';

const rhs = ["A+", "B+", "AB+", "O+", "A-", "B-", "AB-", "O-"]
const genders = ["M", "F"];

const today = () => {
    let date = new Date();

    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();
    let today = `${year}-${month}-${day}`;
    return today;
}

const formatDate = (obj) => {
    let date = new Date(obj);

    let day = date.getDate();
    if (day < 10) {
        day = "0" + day;
    }
    let month = date.getMonth() + 1;
    if (month < 10) {
        month = "0" + month;
    }
    let year = date.getFullYear();
    let today = `${year}-${month}-${day}`;
    return today;
}

const PersonInfo = ({ errors, setErrors }) => {

    const dispatch = useDispatch();
    const clientData = useSelector(selectClientData);

    const dispatchClientData = (e) => {
        let aux = { ...errors };
        aux[e.target.name] = null;
        setErrors(aux);
        dispatch(setClientData({ attribute: e.target.name, value: e.target.value }))
    }
    const getClientData = (attribute) => {
        return clientData[attribute] ? clientData[attribute] : ""
    }

    const initBirthday = () => {
        if (getClientData("birthday") !== "") {
            return getClientData("birthday");
        }
        else {
            dispatch(setClientData({ attribute: "birthday", value: today() }))
            return dayjs(today())
        }
    }

    const [birthday, setBirthday] = useState("");
    const handleChange = (newValue) => {
        dispatch(setClientData({ attribute: "birthday", value: formatDate(newValue) }))
        setBirthday(newValue);
    };

    useEffect(() => {
        setBirthday(initBirthday());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Fragment>
            <Typography variant="h6" gutterBottom>
                Datos Personales
            </Typography>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                    <TextField
                        error={errors.firstName && getClientData("firstName").length === 0 ? true : false}
                        required
                        id="firstName"
                        name="firstName"
                        label="Nombres"
                        fullWidth
                        variant="standard"
                        value={getClientData("firstName")}
                        onChange={(e) => dispatchClientData(e)}
                        helperText={errors.firstName && getClientData("firstName").length === 0 ? errors.firstName : null}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        error={errors.lastName && getClientData("lastName").length === 0 ? true : false}
                        required
                        id="lastName"
                        name="lastName"
                        label="Apellidos"
                        fullWidth
                        variant="standard"
                        value={getClientData("lastName")}
                        onChange={(e) => dispatchClientData(e)}
                        helperText={errors.lastName && getClientData("lastName").length === 0 ? errors.lastName : null}
                    />
                </Grid>
                <Grid item xs={6} sm={3}>
                    <TextField
                        error={errors.rh && getClientData("rh").length === 0 ? true : false}
                        required
                        select
                        id="rh"
                        name="rh"
                        label="RH"
                        fullWidth
                        variant="standard"
                        value={getClientData("rh")}
                        onChange={(e) => dispatchClientData(e)}
                        helperText={errors.rh && getClientData("rh").length === 0 ? errors.rh : null}
                    >
                        {rhs.map((option) => (
                            <MenuItem key={option} value={option}>
                                {option}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item xs={6} sm={4}>
                    <TextField
                        error={errors.gender && getClientData("gender").length === 0 ? true : false}
                        required
                        select
                        id="gender"
                        name="gender"
                        label="Género"
                        fullWidth
                        variant="standard"
                        value={getClientData("gender")}
                        onChange={(e) => dispatchClientData(e)}
                        helperText={errors.gender && getClientData("gender").length === 0 ? errors.gender : null}

                    >
                        {genders.map((option) => (
                            <MenuItem key={option} value={option}>
                                {option}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item xs={12} sm={5}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <MobileDatePicker
                            label="Fecha de Nacimiento"
                            value={birthday}
                            onChange={handleChange}
                            renderInput={(params) =>
                                <TextField {...params}
                                    error={errors.birthday && getClientData("birthday").length === 0 ? true : false}
                                    required
                                    id="birthday"
                                    name="birthday"
                                    label="Fecha de Nacimiento"
                                    fullWidth
                                    variant="standard"
                                    helperText={errors.birthday && getClientData("birthday").length === 0 ? errors.birthday : null}
                                />}
                        />
                    </LocalizationProvider>
                </Grid>
                <Grid item xs={12} sm={8}>
                    <TextField
                        error={errors.id ? true : false}
                        required
                        id="id"
                        name="id"
                        label="Documento de identidad"
                        fullWidth
                        variant="standard"
                        value={getClientData("id")}
                        onChange={(e) => dispatchClientData(e)}
                        helperText={errors.id ? errors.id : null}
                    />
                </Grid>
            </Grid>
        </Fragment>
    );
}
export default PersonInfo;