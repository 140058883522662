/* eslint-disable no-unused-vars */
import React, { Fragment, useEffect } from "react";

import styles from './AdminPage.module.css';
import ResponsiveAppBar from "../../Navs/ResponsiveAppBar/ResponsiveAppBar ";
import { Backdrop, Box, CircularProgress, Grid } from "@mui/material";
import ViewPlans from "../../ViewPlans/ViewPlans";
import ViewClients from "../../ViewClients/ViewClients";
import useToken from "../../../hooks/useToken";




const AdminPage = () => {


    const [token, setToken, verifyToken] = useToken();


    useEffect(() => {
        //verifyToken();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Fragment>
            <div className={styles.mainFragment}>
                <ResponsiveAppBar />
                {token !== "valid" ?
                    <Box sx={{ flex: 1, overflow: "hidden", display: "flex", flexDirection: "column", padding: "8px", boxSizing: "border-box", bgcolor: "secondary.light" }}>
                        <Grid container columnSpacing={2} rowSpacing={2} sx={{ flex: 1 }}>
                            <Grid item xs={12} md={12} lg={4} align="center" container rowSpacing={2} direction="column" >
                                <Grid item align="center">
                                    <ViewPlans />
                                </Grid>
                            </Grid>
                            <Grid item xs={12} md={12} lg={4} align="center" container rowSpacing={2} direction="column"  >
                                <Grid item align="center">
                                    <ViewClients />
                                </Grid>

                            </Grid>
                        </Grid>
                    </Box>
                    :
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={token === "waiting"}
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop>}
            </div>
        </Fragment>
    );
}

export default AdminPage;