/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { forwardRef, Fragment, lazy, useEffect, useRef, useState } from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import axios from 'axios';
import { ButtonGroup, ClickAwayListener, Dialog, DialogContent, DialogTitle, Fab, Grow, IconButton, InputBase, ListItemIcon, Menu, Popover, Select } from '@mui/material';
import { ListItemText, MenuItem, MenuList, Popper, Skeleton, Slide, TableHead, Tooltip, useMediaQuery, useTheme } from '@mui/material';
import { Table, TableBody, TableCell, TableContainer, TableRow, TableFooter, TablePagination } from '@mui/material';

import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import ErrorIcon from '@mui/icons-material/Error';
import NotInterestedIcon from '@mui/icons-material/NotInterested';

import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import PaymentIcon from '@mui/icons-material/Payment';
import FingerprintIcon from '@mui/icons-material/Fingerprint';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';

import RegisterClient from '../RegisterClient/RegisterClient';
import PaymentDialog from './PaymentDialog';
import UserDialog from './UserDialog';
import RegisterFingerprintDialog from './RegisterFingerPrintDialog';

import CachedIcon from '@mui/icons-material/Cached';
import FilterListIcon from '@mui/icons-material/FilterList';
import SearchIcon from '@mui/icons-material/Search';


const SlideTransition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function TablePaginationActions(props) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
        onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
        onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <Box sx={{ flexShrink: 0, ml: 2.5 }}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label="previous page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </Box>
    );
}

const whatsapp = "https://wa.me/";

function numberWithDots(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
}

const warnMessage = (data) => {
    console.log(data);
    let phoneNumber = data.phone.split(" ");
    let msg = `Hola ${data.firstName},
    Este es un mensaje automatizado de WARRIORS COMBAT para recordarte que tu plan "${data.plan}" por un valor de $${numberWithDots(data.price)} COP vence en ${data.days} días
    Agradecemos tu pago puntual para seguir disfrutando de la experiencia WARRIOR`
    if (phoneNumber.length == 1) {
        return `${whatsapp}57${data.phone}?text=${encodeURIComponent(msg)}`
    }
    else {
        return `${whatsapp}${phoneNumber[0].substring(1)}${phoneNumber[1]}?text=${encodeURIComponent(msg)}`
    }
}

const passedMessage = (data) => {
    let phoneNumber = data.phone.split(" ");
    let msg = `Hola ${data.firstName},
    Este es un mensaje automatizado de WARRIORS COMBAT para recordarte que tu plan "${data.plan}" por un valor de $${numberWithDots(data.price)} COP vencío hace ${data.days} días
    Agradecemos tu pago para seguir disfrutando de la experiencia WARRIOR`
    if (phoneNumber.length == 1) {
        return `${whatsapp}57${data.phone}?text=${encodeURIComponent(msg)}`
    }
    else {
        return `${whatsapp}${phoneNumber[0].substring(1)}${phoneNumber[1]}?text=${encodeURIComponent(msg)}`
    }
}

const SplitButtons = (props) => {

    const [payDialog, setPayDialog] = useState(false);
    const [fingerprintDialog, setFingerprintDialog] = useState(false);
    const [clientDialog, setClientDialog] = useState(false);

    const [open, setOpen] = useState(false);
    const anchorRef = useRef(null);
    const [selectedIndex, setSelectedIndex] = useState(0);

    const handleOptionSelect = () => {
        console.info(`You clicked ${options[selectedIndex].label}`);
        options[selectedIndex].action();
    };

    const handleToggleMenu = () => {
        setOpen((prevOpen) => !prevOpen);
    };
    const handleCloseMenu = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };
    const handleMenuItemClick = (event, index) => {
        setSelectedIndex(index);
        setOpen(false);
    };

    const handleNewTab = (link) => {
        window.open(link, '_blank').focus();
    }

    const options = props.status === "Active" ? [
        {
            label: "Ver Cliente",
            icon: <PersonSearchIcon />,
            color: "secondary",
            action: function () { setClientDialog(true) }
        },
        {
            label: "Notificar Pago",
            icon: <WhatsAppIcon />,
            bgColor: "#25D366",
            hoverColor: "#128C7E",
            action: function () { handleNewTab(props.message) }
        },
        {
            label: "Registrar Pago",
            icon: <PaymentIcon />,
            color: "warning",
            action: function () { setPayDialog(true) }
        },
        {
            label: "Registrar Huella",
            icon: <FingerprintIcon />,
            color: "info",
            action: function () { setFingerprintDialog(true) }
        }]
        :
        [
            {
                label: "Ver Cliente",
                icon: <PersonSearchIcon />,
                color: "secondary",
                action: function () { setClientDialog(true) }
            },
        ];

    return (
        <>
            <ButtonGroup variant="contained" ref={anchorRef} size="small" color="secondary">
                <Button onClick={handleOptionSelect}
                    color={options[selectedIndex].color ? options[selectedIndex].color : undefined}
                    sx={options[selectedIndex].bgColor ? {
                        bgcolor: options[selectedIndex].bgColor,
                        "&:hover": {
                            bgcolor: options[selectedIndex].hoverColor,
                        }

                    } : {}}>{options[selectedIndex].icon}</Button>
                <Button
                    size="small"
                    aria-controls={open ? `split-button-menu${props.id}` : undefined}
                    aria-expanded={open ? 'true' : undefined}
                    aria-label="select merge strategy"
                    aria-haspopup="menu"
                    onClick={handleToggleMenu}
                    color={options[selectedIndex].color ? options[selectedIndex].color : undefined}
                    sx={options[selectedIndex].bgColor ? {
                        bgcolor: options[selectedIndex].bgColor,
                        "&:hover": {
                            bgcolor: options[selectedIndex].hoverColor,
                        }

                    } : {}}
                >
                    <ArrowDropDownIcon />
                </Button>
            </ButtonGroup>
            <Popper
                sx={{
                    zIndex: 1,
                }}
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                placement="left"
            >
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin:
                                placement === 'bottom' ? 'center top' : 'center bottom',
                        }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleCloseMenu}>
                                <MenuList id={`split-button-menu${props.id}`} autoFocusItem>
                                    {options.map((option, index) => (
                                        <MenuItem
                                            key={option.label}
                                            selected={index === selectedIndex}
                                            onClick={(event) => handleMenuItemClick(event, index)}
                                        >
                                            <ListItemIcon>
                                                {option.icon}
                                            </ListItemIcon>
                                            <ListItemText>
                                                {option.label}
                                            </ListItemText>
                                        </MenuItem>
                                    ))}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
            <PaymentDialog {...props} payDialog={payDialog} setPayDialog={setPayDialog} />
            <UserDialog {...props} clientDialog={clientDialog} setClientDialog={setClientDialog} />
            <RegisterFingerprintDialog {...props} fingerprintDialog={fingerprintDialog} setFingerprintDialog={setFingerprintDialog} />
        </>
    );
}

const GroupedButtons = (props) => {

    const [payDialog, setPayDialog] = useState(false);
    const [fingerprintDialog, setFingerprintDialog] = useState(false);
    const [clientDialog, setClientDialog] = useState(false);


    const handleNewTab = (link) => {
        window.open(link, '_blank').focus();
    }

    return (
        <>
            <ButtonGroup variant="contained" size="small" color="secondary">
                <Button
                    onClick={(e) => setClientDialog(true)}
                    color="secondary">
                    <Tooltip title="Ver Cliente">
                        <PersonSearchIcon />
                    </Tooltip>
                </Button>
                {props.status === "Active" ?
                    <Fragment>
                        <Button
                            onClick={(e) => handleNewTab(props.message)}
                            sx={{
                                bgcolor: "#25D366",
                                "&:hover": {
                                    bgcolor: "#128C7E",
                                }
                            }}>
                            <Tooltip title="Notificar Pago">
                                <WhatsAppIcon sx={{ color: "white" }} />
                            </Tooltip>
                        </Button>
                        <Button
                            onClick={(e) => setPayDialog(true)}
                            color="warning">
                            <Tooltip title="Registrar Pago">
                                <PaymentIcon />
                            </Tooltip>
                        </Button>
                        <Button
                            onClick={(e) => setFingerprintDialog(true)}
                            color="info">
                            <Tooltip title="Registrar Huella">
                                <FingerprintIcon />
                            </Tooltip>
                        </Button>
                    </Fragment>
                    : null}

            </ButtonGroup>
            <PaymentDialog {...props} payDialog={payDialog} setPayDialog={setPayDialog} />
            <UserDialog {...props} clientDialog={clientDialog} setClientDialog={setClientDialog} />
            <RegisterFingerprintDialog {...props} fingerprintDialog={fingerprintDialog} setFingerprintDialog={setFingerprintDialog} />
        </>
    )
}

const ClientRow = ({ row, idx, getClients }) => {

    const theme = useTheme();
    const xsScreen = useMediaQuery(theme.breakpoints.down(550));
    const phoneScreen = useMediaQuery(theme.breakpoints.down(450));

    const cellStyle = {
        width: 100,
        maxWidth: 100,
        overflow: "hidden",
        textOverflow: "elipsis",
        borderStyle: "border-box"
    };
    const iconCellStyle = {
        width: 25,
        maxWidth: 25,
        paddingLeft: 0,
        paddingRight: 0,
        overflow: "hidden",
        borderStyle: "border-box"
    };
    const smActionCellStyle = {
        width: 90,
        maxWidth: 90,
        overflow: "hidden",
        borderStyle: "border-box"
    };
    const mdActionCellStyle = {
        width: 200,
        maxWidth: 200,
        overflow: "hidden",
        borderStyle: "border-box"
    };

    let oneDay = 24 * 60 * 60 * 1000;
    let today = new Date();
    let nextPay = new Date(row.lastPay);
    nextPay.setMonth(nextPay.getMonth() + 1);
    row.nextPay = nextPay.getFullYear() + "-" + (nextPay.getMonth() + 1) + "-" + (nextPay.getDate() + 1);
    let upToDate = "ok";
    let data = { ...row };
    let days = Math.round((nextPay - today) / oneDay);

    if (days < 0) {
        upToDate = "passed";
        data.days = Math.abs(days);
        row.message = passedMessage(data);
    } else if (days <= 5) {
        upToDate = "warn";
        data.days = Math.abs(days);
        row.message = warnMessage(data);
    } else {
        data.days = Math.abs(days);
        row.message = warnMessage(data);
    }

    return (
        <TableRow>
            <TableCell component="th" scope="row" style={cellStyle}>
                {row.firstName}
                {phoneScreen ? " " + row.lastName : null}
            </TableCell>
            <TableCell style={cellStyle} sx={phoneScreen ? { display: 'none' } : {}} >
                {row.lastName}
            </TableCell>
            <TableCell style={iconCellStyle}>
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                    {
                        row.status !== "Active" ?
                            <NotInterestedIcon />
                            :
                            upToDate === "passed" ?
                                <CancelIcon color="error" />
                                :
                                upToDate === "warn" ?
                                    <ErrorIcon color="warning" />
                                    :
                                    <CheckCircleIcon color="success" />

                    }
                </Box>
            </TableCell>
            <TableCell style={!xsScreen ? mdActionCellStyle : smActionCellStyle}>
                {!xsScreen ?
                    <GroupedButtons {...row} getClients={getClients} />
                    :
                    <SplitButtons {...row} getClients={getClients} />
                }
            </TableCell>

        </TableRow>
    )

}

const ClientTable = ({ rows, setRows }) => {

    const theme = useTheme();
    const smScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const xsScreen = useMediaQuery(theme.breakpoints.down(550));
    const phoneScreen = useMediaQuery(theme.breakpoints.down(450));

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [rowsOptions, setRowsOptions] = useState([5, 10, 25, { label: 'All', value: -1 }])


    const [filterID, setFilterID] = useState("");
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleOpenFilter = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseFilter = () => {
        setAnchorEl(null);
    };

    const filteredRows = (clients) => {
        let filtered = clients;
        if (filterID !== "") {
            if (!isNaN(filterID)) {
                filtered = clients.filter((value, index) => value.id.toString().includes(filterID))
            }
            else {
                filtered = clients.filter((value, index) => {
                    let fullName = value.firstName.toLowerCase() + " " + value.lastName.toLowerCase();
                    return fullName.includes(filterID.toLowerCase())
                })
            }

        }
        return filtered
    }

    const iconCellStyle = {
        width: 25,
        maxWidth: 25,
        paddingLeft: 0,
        paddingRight: 0,
        overflow: "hidden",
        borderStyle: "border-box"
    };
    const filterCellStyle = {
        paddingTop: 0,
        paddingBottom: 0,
    }

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page >= 0 ? Math.max(0, ((1 + page) * rowsPerPage - rows.length) + 0) : 0;

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const getClients = () => {

        axios.get(process.env.REACT_APP_API_DOMAIN + "/users", { withCredentials: true })
            .then(result => result.data)
            .then(response => {
                const active = response.filter((item) => item.status === "Active");
                const inactive = response.filter((item) => item.status !== "Active");
                setRows([...active, ...inactive]);
            })
            .catch(error => {
                console.log(error);
            })
    }


    useEffect(() => {
        getClients();
    }, [])

    useEffect(() => {
        if (smScreen) {
            setRowsOptions([5]);
        }
        else {
            setRowsOptions([5, 10, 25, { label: 'All', value: -1 }]);
        }
    }, [smScreen])

    return (
        <TableContainer component={Paper} sx={{ mt: "8px" }}>
            <Table sx={{}} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell>Nombre</TableCell>
                        <TableCell sx={phoneScreen ? { display: 'none' } : {}}>Apellido</TableCell>
                        <TableCell style={iconCellStyle}></TableCell>
                        <TableCell style={filterCellStyle}>
                            <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "flex-end" }}>

                                <IconButton aria-describedby="filter-popover" variant="contained" onClick={handleOpenFilter}>
                                    <FilterListIcon />
                                </IconButton>
                                <Popover
                                    id="filter-popover"
                                    open={open}
                                    anchorEl={anchorEl}
                                    onClose={handleCloseFilter}
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'left',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                >
                                    <Paper
                                        component="form"
                                        sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: "100%" }}
                                    >
                                        <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
                                            <SearchIcon />
                                        </IconButton>
                                        <InputBase
                                            sx={{ ml: 1, flex: 1 }}
                                            placeholder="Buscar"
                                            value={filterID}
                                            onChange={(e) => setFilterID(e.target.value)}
                                        />
                                    </Paper>
                                </Popover>

                            </Box>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {(rowsPerPage > 0
                        ? filteredRows(rows).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        : filteredRows(rows)
                    ).map((row, idx) => <ClientRow key={row.firstName + idx} row={row} idx={idx} getClients={getClients} />)}
                    {emptyRows > 0 && (
                        <TableRow style={{ height: 66 * emptyRows }}>
                            <TableCell colSpan={5} />
                        </TableRow>
                    )}
                </TableBody>
                <TableFooter>
                    <TableRow>
                        <TablePagination
                            rowsPerPageOptions={rowsOptions}
                            labelRowsPerPage="Filas"
                            colSpan={5}
                            count={filteredRows(rows).length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            SelectProps={{
                                inputProps: {
                                    'aria-label': 'rows per page',
                                },
                                native: true,
                            }}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            ActionsComponent={TablePaginationActions}
                        />
                    </TableRow>
                </TableFooter>
            </Table>
        </TableContainer >
    );
}

const AddModal = ({ setRows }) => {
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <Fab size="small" color="secondary" aria-label="add" onClick={handleClickOpen}>
                <AddIcon color="white" />
            </Fab>
            <Dialog
                fullWidth={true}
                maxWidth={"sm"}
                open={open}
                TransitionComponent={SlideTransition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle sx={{ m: 0, p: 2 }} >
                    Registro de Clientes
                    <IconButton
                        aria-label="close"
                        onClick={handleClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>

                </DialogTitle>
                <DialogContent dividers>
                    <RegisterClient setRows={setRows} />
                </DialogContent>

            </Dialog>
        </>
    )
}

const ReloadData = ({ setRows }) => {

    const getClients = () => {

        axios.get(process.env.REACT_APP_API_DOMAIN + "/users", { withCredentials: true })
            .then(result => result.data)
            .then(response => {
                console.log("REFRESH OK");
                const active = response.filter((item) => item.status === "Active");
                const inactive = response.filter((item) => item.status !== "Active");
                setRows([...active, ...inactive]);
            })
            .catch(error => {
                console.log(error);
            })
    }

    return (
        <Fab size="small" color="secondary" aria-label="add" onClick={(e) => getClients()}>
            <CachedIcon color="white" />
        </Fab>
    )
}

const loadingRows = () => {
    let lRows = {
        firstName: <Skeleton variant="text" sx={{ fontSize: '1rem' }} />,
        lastName: <Skeleton variant="text" sx={{ fontSize: '1rem' }} />

    }

}

const ViewClients = () => {

    const theme = useTheme();


    const [currentBreakPoint, setCurrentBreakPoint] = useState("lg");

    const greaterThanLg = useMediaQuery(theme.breakpoints.up("lg"));
    const greaterThanMid = useMediaQuery(theme.breakpoints.up("md"));
    const smallToMid = useMediaQuery(theme.breakpoints.down("md"));


    const [rows, setRows] = useState([]);

    useEffect(() => {
        if (greaterThanLg) {
            console.log("Greater than large");
            setCurrentBreakPoint("sm");
        }
        else if (greaterThanMid) {
            console.log("Greater than mid");
            setCurrentBreakPoint("sm");
        }
        else if (smallToMid) {
            console.log("Smaller to mid");
            setCurrentBreakPoint("sm");
        }
    }, [greaterThanMid, smallToMid, greaterThanLg])



    return (
        <Box component="main" maxWidth={currentBreakPoint}>
            <Paper variant="outlined" sx={{ my: { xs: 2, md: 0 }, p: { xs: 2, md: 2 } }}>
                <Box sx={{ position: 'relative' }}>
                    <Box sx={{ position: "absolute", left: "0px", top: "0px" }}>
                        <ReloadData setRows={setRows} />
                    </Box>
                    <Typography component="h1" variant="h4" align="center">
                        Mis Clientes
                    </Typography>
                    <Box sx={{ position: "absolute", right: "0px", top: "0px" }}>
                        <AddModal setRows={setRows} />
                    </Box>
                </Box>
                <ClientTable rows={rows} setRows={setRows} />
            </Paper>
        </Box>
    );
}
export default ViewClients;









