/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { Fragment, useEffect, useState } from "react";

import styles from './AdmissionPage.module.css';
import ResponsiveAppBar from "../../Navs/ResponsiveAppBar/ResponsiveAppBar ";
import { Backdrop, Box, CircularProgress, Typography } from "@mui/material";
import useToken from "../../../hooks/useToken";

import FingerprintIcon from '@mui/icons-material/Fingerprint';

import axios from "axios";
import useFingerReader from "../../../hooks/useFingerReader";

import useSound from 'use-sound';

const AdmissionPage = () => {

    const [token, setToken, verifyToken] = useToken();
    const [client, setClient] = useState({});

    const [currentRead, startReading, stopReading, clearRead] = useFingerReader();

    const [playError, { stopError }] = useSound("/static/sounds/error.mp3");
    const [playSuccess, { stopSuccess }] = useSound("/static/sounds/success.mp3");

    useEffect(() => {
        verifyToken();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    useEffect(() => {
        if (token === "valid") {
            startReading();
        }
    }, [token])
    useEffect(() => {
        if (currentRead !== null)
            verifyUser();
    }, [currentRead])

    const verifyUser = () => {
        console.log(currentRead);
        let data = {
            fmv: currentRead,
        }
        axios.post(process.env.REACT_APP_API_DOMAIN + "/finger/verify", data, { withCredentials: true })
            .then(response => response.data)
            .then(result => {
                let oneDay = 24 * 60 * 60 * 1000;
                let today = new Date();
                let nextPay = new Date(result.lastPay);
                nextPay.setMonth(nextPay.getMonth() + 1);
                result.ok = true;
                let days = Math.round((nextPay - today) / oneDay);
                if (days < 0) {
                    result.ok = false;
                    playError();
                }
                else {
                    playSuccess();
                }
                result.days = Math.abs(days);
                console.log(result);
                setClient(result);
                setTimeout(() => {
                    setClient({});
                }, 5000)

                clearRead();

            })
            .catch(error => {
                console.log(error);
                setClient({ id: true, nn: true });
                setTimeout(() => {
                    setClient({});
                }, 2000)
                clearRead();
            })
    }


    return (
        <Fragment>

            <div className={styles.mainFragment}>
                <ResponsiveAppBar />
                {token === "valid" ?
                    <Box sx={{
                        flex: 1,
                        overflow: "hidden",
                        display: "flex",
                        flexDirection: "column",
                        padding: "8px",
                        boxSizing: "border-box",
                        bgcolor: "secondary.light",
                        alignItems: "center",
                        justifyContent: "space-evenly"
                    }}>

                        <Typography variant="h2" gutterBottom color="white" sx={{ mt: 4, mb: 4 }} >
                            {
                                !client.id ?
                                    "Identificando Usuario"
                                    :
                                    client.nn ?
                                        "Usuario no identificado"
                                        :
                                        `Identificado: ${client.firstName} ${client.lastName}`
                            }
                        </Typography>

                        <FingerprintIcon sx={{ fontSize: "300px" }}
                            className={
                                !client.id ?
                                    styles.breathingIcon
                                    :
                                    client.nn ?
                                        styles.errorIcon
                                        :
                                        client.ok ?
                                            styles.successIcon
                                            :
                                            styles.errorIcon
                            } />
                        <Typography variant="h2" gutterBottom color="white" sx={{ mt: 4, mb: 4 }} >
                            {
                                !client.id ?
                                    "Ubique su dedo registrado en el lector"
                                    :
                                    client.nn ?
                                        `Inténtelo de nuevo`
                                        :
                                        client.ok ?
                                            `Acceso concedido, su plan vence en ${client.days} dias`
                                            :
                                            `Acceso denegado, su plan venció hace ${client.days} dias`
                            }
                        </Typography>

                    </Box>
                    :
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={token === "waiting"}
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop>}
            </div>
        </Fragment>
    );
}

export default AdmissionPage;