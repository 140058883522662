import React from "react";
import { Grid, Container, Typography } from "@mui/material/";

import styles from "./ProductValues.module.css";


function ProductValues() {

    return (
        <section className={styles.root}>
            <Container className={styles.container}>
                <img
                    style={{ display: "none" }}
                    src="/static/png/appCurvyLines.png"
                    className={styles.curvyLines}
                    alt="curvy lines"
                />
                <Grid container spacing={5}>
                    <Grid item xs={12} md={4}>
                        <div className={styles.item}>
                            <img
                                className={styles.image}
                                src="/static/svg/Kick.svg"
                                alt="Kick"
                            />
                            <Typography
                                variant="h6"
                                noWrap
                                className={styles.title}
                                sx={{
                                    fontFamily: 'monospace',
                                    fontWeight: 700,
                                    color: 'inherit',
                                    textDecoration: 'none',
                                }}
                            >
                                Concentración
                            </Typography>
                            <Typography
                                variant="h5"
                                className={styles.title}
                                sx={{
                                    fontFamily: 'monospace',
                                    fontWeight: 100,
                                    color: 'inherit',
                                    textDecoration: 'none',
                                }}
                            >
                                Es importante poder escuchar al instructor y seguir las indicaciones.
                                Escuchar y prestar atención no solo es importante para aprender una nueva habilidad,
                                sino que también es importante para aprender a estar seguro mientras se realiza.
                            </Typography>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <div className={styles.item}>
                            <img
                                className={styles.image}
                                src="/static/svg/Korea.svg"
                                alt="Korea"
                            />
                            <Typography
                                variant="h6"
                                noWrap
                                className={styles.title}
                                sx={{
                                    fontFamily: 'monospace',
                                    fontWeight: 700,
                                    color: 'inherit',
                                    textDecoration: 'none',
                                }}
                            >
                                Disciplina
                            </Typography>
                            <Typography
                                variant="h5"
                                className={styles.title}
                                sx={{
                                    fontFamily: 'monospace',
                                    fontWeight: 100,
                                    color: 'inherit',
                                    textDecoration: 'none',
                                }}
                            >
                                Para lograr sus objetivos y avanzar al siguiente nivel, su hijo desarrollará disciplina.
                                Asistir a clases de artes marciales semanalmente y esforzarse por mejorar sus habilidades
                                le brinda a su hijo oportunidades regulares para aprender y practicar la disciplina.
                            </Typography>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <div className={styles.item}>
                            <img
                                className={styles.image}
                                src="/static/svg/Yin_yang.svg"
                                alt="Yin yang"
                            />
                            <Typography
                                variant="h6"
                                noWrap
                                className={styles.title}
                                sx={{
                                    fontFamily: 'monospace',
                                    fontWeight: 700,
                                    color: 'inherit',
                                    textDecoration: 'none',
                                }}
                            >
                                Respeto
                            </Typography>
                            <Typography
                                variant="h5"
                                className={styles.title}
                                sx={{
                                    fontFamily: 'monospace',
                                    fontWeight: 100,
                                    color: 'inherit',
                                    textDecoration: 'none',
                                }}
                            >
                                Una de las primeras lecciones que su hijo aprenderá en una clase de artes marciales es
                                cómo dirigirse a su maestro, o como decimos, "Sabonim". En las artes marciales, la expectativa
                                es tratar a todos con respeto, tanto al maestro como a los compañeros.
                            </Typography>
                        </div>
                    </Grid>
                </Grid>
                <br />
                <br />
                {/* Espacio para registrar estudiantes*/}
            </Container>
        </section>
    );
}


export default ProductValues;
